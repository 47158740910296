module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"preloadTags":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"title":"Planète Durable","language":"fr","description":" Planète Durable partage des conseils, innovations et initiatives veillant à préserver notre planète.","canonical":"https://www.planete-durable.fr","openGraph":{"type":"website","locale":"fr_FR","url":"https://www.planete-durable.fr","description":" Planète Durable partage des conseils, innovations et initiatives veillant à préserver notre planète.","title":"Planète Durable","site_name":"Planète Durable"},"twitter":{"site":"DurablePlanete","cardType":"summary_large_image"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Planète Durable","short_name":"Planète Durable","start_url":"/","background_color":"#433e85","theme_color":"#433e85","display":"minimal-ui","icon":"src/images/logo.jpg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b3d916e6944f18fe3d0bbd9565156c8f"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
